<template>
  <PollEditView disabled />
</template>

<script>
import PollEditView from '@/views/poll/PollEditView'

export default {
  name: 'PollView',
  components: {
    PollEditView
  }
}
</script>
